import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Identidad Corporativa Integral",
        icon: "pe-7s-portfolio", // icono de portafolio para identidad corporativa
        description:
          "Creamos y fortalecemos la identidad de tu empresa, asegurando que tu marca se destaque en el mercado.",
      },
      {
        title: "Registro de Marca",
        icon: "pe-7s-flag", // icono de bandera para registro de marca
        description:
          "Te ayudamos a proteger tu marca con un registro oficial ante el IMPI, asegurando su exclusividad y reconocimiento.",
      },
      {
        title: "Insumos para Talleres Creativos",
        icon: "pe-7s-paint-bucket", // icono de cubeta de pintura para insumos creativos
        description:
          "Proveemos insumos esenciales para talleres de serigrafía y otras técnicas gráficas, apoyando a creativos y emprendedores.",
      },
      {
        title: "Activación de Marcas",
        icon: "pe-7s-light", // icono de foco para activación de marcas
        description:
          "Diseñamos estrategias de activación y promoción que incrementan la visibilidad y credibilidad de tu marca en el mercado.",
      },
      {
        title: "Planogramas y Material POP",
        icon: "pe-7s-display1", // icono de pantalla para planogramas y material POP
        description:
          "Elaboramos planogramas y material POP que destacan tus productos en puntos de venta, maximizando su impacto visual.",
      },
      {
        title: "Desarrollo Web Profesional",
        icon: "pe-7s-browser", // icono de navegador para desarrollo web
        description:
          "Ofrecemos soluciones de desarrollo web a medida para fortalecer la presencia digital de tu empresa, con un enfoque en diseño atractivo y funcionalidad óptima.",
      },
      {
        title: "Capacitación en Lenguaje Digital",
        icon: "pe-7s-study", // icono de estudio para capacitación
        description:
          "Ofrecemos talleres y capacitación para emprendedores sobre el uso eficaz del lenguaje digital y marketing en línea.",
      },
      {
        title: "Herramientas Especializadas en Diseño",
        icon: "pe-7s-tools", // icono de herramientas para herramientas especializadas en diseño
        description:
          "Utilizamos herramientas como Corel Draw y Sony Vegas para garantizar que cada proyecto gráfico sea de la más alta calidad.",
      },
      {
        title: "Diseño Excepcional y Creatividad",
        icon: "pe-7s-paint", // icono de pintura para diseño excepcional
        description:
          "Nuestro equipo de expertos asegura que cada diseño refleje la creatividad y perfección que tu marca necesita.",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Nuestros Servicios"
              description="Ofrecemos una amplia gama de soluciones en diseño gráfico, que incluyen la creación de identidad corporativa, desarrollo de páginas web, marketing digital, serigrafía, producción de videos y activación de marcas. Nos comprometemos a fortalecer la imagen de tu empresa con creatividad y excelencia."
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
