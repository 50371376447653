import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Input, Button, FormFeedback } from "reactstrap";
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const Section = () => {
  const [cargando, setCargando] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      subject: '',
      comments: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, ingresa tu nombre"),
      email: Yup.string().email("Correo inválido").required("Por favor, ingresa tu correo electrónico"),
      subject: Yup.string().required("Por favor, ingresa el asunto"),
      comments: Yup.string().required("Por favor, ingresa tu mensaje"),
    }),
    onSubmit: (values) => {
      setCargando(true);
      console.log("values", values);
      const { name, email, comments } = values;
      const nombreProp = 'Israel Bustos García';
      const correoProp = 'jesuscarm1@gmail.com';
      const pagina = 'israelbg.com.mx';

      const data = {
        nombre: name,
        correo: email,
        mensaje: comments,
        nombreProp,
        correoProp,
        pagina
      };

      axios.post('https://taeconta.com/api/public/api/correos/publicos', data)
        .then(response => {
          console.log('Correo enviado con éxito:', response.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Correo enviado exitosamente",
            showConfirmButton: false,
            timer: 1500
          });
          setCargando(false);
        })
        .catch(error => {
          console.error('Error al enviar el correo:', error);
          Swal.fire(
            "Error al enviar el correo",
            error.message + ", " + (error.response?.data?.message || "Error desconocido"),
            "error"
          );
          setCargando(false);
        });
    }
  });

  return (
    <React.Fragment>
      <section
        className="section bg-home home-half home-registration active"
        id="home"
      >
        <div className="bg-overlay"></div>
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="vertical-content">
                <Col lg="7" className="text-white text-start">
                  <div className="mt-4">
                    <h4 className="home-small-title">Perfección en Diseño</h4>
                    <h1 className="home-title">
                      Amamos transformar ideas en identidad visual
                    </h1>
                    <p className="pt-3 home-desc home-subtitle-width-100">
                      Aplicamos creatividad y excelencia en
                      cada proyecto, elevando la imagen de tu empresa a través
                      del diseño gráfico y el branding profesional.
                    </p>
                  </div>
                </Col>
                
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Section;
