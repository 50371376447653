import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";

class AboutUs extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "Israel Bustos García",
        image: "assets/images/a285.png",
        post: "Director Creativo y Especialista en Branding Corporativo",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section" id="about">
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="about-title mx-auto text-center">
                  <h2 className="font-weight-light">
                    Un estudio digital especializado en diseño gráfico y
                    branding corporativo
                  </h2>
                  <p className="text-muted pt-4">
                    Nos dedicamos a transformar la imagen de tu
                    empresa a través de soluciones creativas y personalizadas en
                    diseño gráfico y branding. Desde la creación de identidades
                    corporativas hasta la activación de marcas, nuestros
                    servicios están diseñados para fortalecer tu presencia en el
                    mercado y asegurar que tu marca se destaque. Con nuestra
                    experiencia y enfoque innovador, ayudamos a empresas a
                    alcanzar la excelencia visual y a conectar de manera
                    efectiva con su audiencia.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-5 d-flex justify-content-center align-items-center">
              {/* Renderizar miembros del equipo */}
              {this.state.members.map((member, key) => (
                <TeamBox
                  key={key}
                  name={member.name}
                  image={member.image}
                  post={member.post}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
